$(function() {
    var objectFitImages = $('#header .c-slider img');
    var bots = /bot|googlebot|crawler|spider|robot|crawling/i;
    if (!bots.test(navigator.userAgent)) {
        objectFitImages.each(function() {
            var image = $(this);
            image
            .wrap('<div class="object-fit-wrapper"></div>')
            .after('<div class="object-fit"></div>');

            // var objectFitWrapper = image.parent();
            var objectFit = image.next();

            objectFit
            .addClass(image.attr('class'))
            .css('background-image', 'url("' + encodeURI(image.attr('src')) + '")')
            .attr('title', image.attr('alt'));

            image.remove();
        });
    }
});
