$(function() {
    var slider = $('.c-slider');

    // Iframe fix
    slider.find('iframe[src]').each(function() {
      var iframe = $(this).get(0);
      iframe.contentWindow.location.href = iframe.src;
    });

    var slickBeforeChange = function(e, slick, currentSlide) {
      var current = $(this).find('[data-slick-index="' + currentSlide + '"]');
      var iframe = current.find('iframe[src]');

      if (!iframe.length) {
        return;
      }

      var command;
      var source = iframe.attr('src');

      if (source.indexOf('vimeo.com') > -1) {
        command = {
          'method': 'pause',
        };
      } else if (source.indexOf('youtube.com') > -1) {
        command = {
          'event': 'command',
          'func': 'pauseVideo',
        };
      } else {
        return;
      }

      if (typeof iframe.get(0).contentWindow === 'undefined' ||
        iframe.get(0).contentWindow === null) {
        return;
      }

      if (typeof command === 'undefined') {
        return;
      }

      iframe.get(0).contentWindow.postMessage(JSON.stringify(command), '*');
    };

    slider.slick({
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        arrows: true,
        focusOnSelect: true,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
    }).on('beforeChange', slickBeforeChange);
});
