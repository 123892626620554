$(function() {
    // Smooth scroll
    $('a').smoothScroll();

    // Iframe resize
    $('.c-map').iFrameResize({
        scrolling: true,
    });

    // Masonry & imagesLoaded
    var $masonry = $('.c-masonry').masonry({
        itemSelector: '.c-masonry-item',
    });
    $masonry.imagesLoaded().progress(function() {
        $masonry.masonry('layout');
    });

    // Description
    $('.c-masonry img[alt]').each(function() {
        var image = $(this);
        var alt = image.attr('alt');
        image.after('<div class="img-description">' + alt + '</div>');
    });

    // Scroll animations
    // ScrollMagic
    var scrollmagic = new $.ScrollMagic.Controller({
        refreshInterval: 1000,
        globalSceneOptions: {
            triggerHook: .9,
        }
    });

    $('html').addClass('scrollmagic');
    var visibleClass = 'animated-visible';

    var selector = '.button';
    var animationClasses = 'animated flipInX';
    $(selector).each(function() {
        var element = $(this);

        var scene = new $.ScrollMagic.Scene({
            triggerElement: element,
            reverse: false,
        })
        .setClassToggle(element, animationClasses)
        .on('start', function(event) {
            var scene = event.target;

            element.addClass(visibleClass);

            _.delay(function() {
                scene.removeClassToggle(true);
            }, 1000);
        })
        // .addIndicators()
        .addTo(scrollmagic);
    });

    var selector = '#header .logo';
    var animationClasses = 'animated pulse';
    $(selector).each(function() {
        var element = $(this);

        var scene = new $.ScrollMagic.Scene({
            triggerElement: element,
            reverse: false,
        })
        .setClassToggle(element, animationClasses)
        .on('start', function(event) {
            var scene = event.target;

            element.addClass(visibleClass);

            _.delay(function() {
                scene.removeClassToggle(true);
            }, 1000);
        })
        // .addIndicators()
        .addTo(scrollmagic);
    });

    // ScrollReveal
    var scrollreveal = ScrollReveal({
        duration: 500,
        viewFactor: 0.33,
    });

    if (scrollreveal.isSupported()) {
        $('html').addClass('scrollreveal');
    }

    // $('#header .dropdown-menu-wrapper > .dropdown.menu > li > a').wrap('<div class="sc-wrapper"></div>');
    var selector = '#header .dropdown-menu-wrapper > .dropdown.menu > li > a';
    scrollreveal.reveal(selector, {}, 100);

    $('.events .event').wrap('<div class="sc-wrapper"></div>');
    var selector = '.events .sc-wrapper';
    scrollreveal.reveal(selector, {}, 100);

    $('.sidebar > *').wrap('<div class="sc-wrapper"></div>');
    var selector = '.sidebar .sc-wrapper';
    scrollreveal.reveal(selector, {}, 100);

    $('#footer > .content > *').wrap('<div class="sc-wrapper"></div>');
    var selector = '#footer .sc-wrapper';
    scrollreveal.reveal(selector, {
        duration: 1000,
    }, 100);
});

$(document).foundation();
