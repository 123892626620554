$(function() {
    var navigation = $('.c-navigation .navigation-both');

    navigation.navigation({
        type: 'push',
        label: false,
        maxWidth: Infinity,
    });

    checkNavigation();

    $(window).on('changed.zf.mediaquery', function() {
        checkNavigation();
    });

    function checkNavigation() {
        if (Foundation.MediaQuery.atLeast('medium')) {
            navigation.navigation('disable');
        } else {
            navigation.navigation('enable');
        }
    }
});
